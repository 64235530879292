@tailwind base;
@tailwind components;
@tailwind utilities;


body::-webkit-scrollbar {
    display: none;
}
@media screen and (min-width:1024px) {
    .thirdPlan{
        box-shadow: -10px 10px 10px #00000041, inset 10px -10px 10px #ffffff40;
    }
    .secondPlan{
        box-shadow: -10px 0 10px #00000041, 10px 0 10px #00000041,  0 10px 10px #00000041;
    }
    .firstPlan{
        box-shadow: 10px 10px 10px #00000041,inset -10px -10px 10px #ffffff40;
    }
}

.thirdPlan,.secondPlan,.firstPlan{
    box-shadow: -10px 0 10px #00000041, 10px 0 10px #00000041,  0 10px 10px #00000041;
}