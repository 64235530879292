/* .pulse-ring {
    content: '';
    width: 55px; 
    height: 55px; 
    background-color: white;
    border-radius: 50%;
    position: absolute;
    left: -6px;
    top: -6px;
    animation: pulsate infinite 2s ;
} */
/* 
@keyframes pulsate {
    0% {
        -webkit-transform: scale(0.9, 0.9);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(1.1, 1.1);
        opacity: 0;
    }
} */

.box-showdow-css{
    backdrop-filter: blur(7px);
  /* background-color: rgba(255, 255, 255, 0.5); */
  border-radius: 15px;
  box-shadow: 35px 35px 68px 0px rgba(157, 177, 255, 0.2), inset -8px -8px 16px 0px rgba(157, 177, 255, 0.6), inset 0px 11px 28px 0px rgb(255, 255, 255);
  transition: all 0.3s;
}
.box-showdow-css-dark{
    backdrop-filter: blur(7px);
  /* background-color: rgba(255, 255, 255, 0.5); */
  border-radius: 15px;
  box-shadow: 35px 35px 68px 0px rgba(21, 21, 21, 0.2), inset -8px -8px 16px 0px rgba(21, 21, 21, 0.6), inset 0px 11px 28px 0px rgb(17, 17, 17);
  transition: all 0.3s;
}

/* .loader {
    position: relative;
    background-color: white;
    padding-left: 1.5em;
    padding-right: 1.5em;
    padding-top: 3em;
    top: -0.4em;
    border-radius: 25px;
    z-index: -1;
    box-shadow: 5px 2px 0px #666666;
  }
  
  .clock {
    position: relative;
    top: -1.4em;
    left: 0.05em;
  }
  
  .stage {
    position: relative;
    left: 0.5em;
    background-color: black;
    width: 4em;
    height: .4em;
    border-radius: 15px;
    box-shadow: 2px 2px 0px #666666;
    z-index: 4;
  }
  
  .hold {
    position: relative;
    background-color: limegreen;
    width: 0.1em;
    height: 4em;
    left: 2.5em;
    top: -.25em;
    animation: 1.3s dangle infinite;
    box-shadow: 4px 4px 0px #666666;
    z-index: -1;
  }
  
  @keyframes dangle {
    0% {
      transform-origin: top center;
      transform: rotate(45deg);
    }
  
    50% {
      transform-origin: top center;
      transform: rotate(-45deg);
    }
  
    100% {
      transform-origin: top center;
      transform: rotate(45deg);
    }
  }
  
  .ball {
    display: inline-block;
    position: relative;
    top: 3em;
    left: -1.2em;
    width: 2.5em;
    height: 2.5em;
    margin: 0;
    border-radius: 50%;
    background: #171717;
    box-shadow: 1px 1px 0px #666666;
  } */

  .input-container {
    display: flex;
    background: white;
    border-radius: 10px;
    background: linear-gradient(135deg, #dbdbdb 0%, #cecece 100%);
    /* box-shadow: 10px 10px 20px #0e1013, -10px -10px 40px #383e4b; */
    padding: 0rem;
    gap: 0.3rem;
  }
  
  .input-container input {
    border-radius: 0.8rem 0 0 0.8rem;
    background: #e1e1e1;
    width: 100%;
    flex-basis: 75%;
    padding: .5rem;
    border: none;
    border: 1px solid transparent;
    color: white;
    transition: all 0.2s ease-in-out;
  }
  
  .input-container input:focus {
    border: 1px solid rgb(46,186,202);
    outline: none;
    /* box-shadow: inset 0px 0px 10px rgba(255, 102, 0, 0.5), inset 0px 0px 10px rgba(255, 212, 59, 0.5), 0px 0px 100px rgba(255, 212, 59, 0.5), 0px 0px 100px rgba(255, 102, 0, 0.5); */
  }
  
  .input-container button {
    flex-basis: 25%;
    padding: .75rem;
    background: linear-gradient(135deg, rgb(123,241,242) 50%, rgb(46,186,202) 100%);
    /* box-shadow: 0px 0px 100px rgb(46,186,202), 0px 0px 100px rgb(46,186,202); */
    font-weight: 900;
    font-size: 13px;
    letter-spacing: 0.005rem;
    text-transform: uppercase;
    color: #23272F;
    border: none;
    width: 100%;
    border-radius: 0 10px 10px 0;
    transition: all 0.2s ease-in-out;
  }
  
  .input-container button:hover {
    background: linear-gradient(135deg, rgb(123,241,242) 50%, rgb(46,186,202) 100%);
    /* box-shadow: 0px 0px 100px rgb(46,186,202), 0px 0px 100px rgb(46,186,202); */
  }
  
  @media (max-width: 500px) {
    /* .input-container {
      flex-direction: column;
    } */
  
   
  
   
  }