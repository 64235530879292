.del {
    position: relative;
    top: 0;
    left: 0;
    width: 320px;
    /* height: 70px; */
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .del div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    box-shadow: 2px 2px 6px 0 rgba(255,255,255,.5),
                -1px -1px 3px 0 rgba(116, 125, 136, .5), 
      inset -2px -2px 3px 0 rgba(255,255,255,.2),
      inset 2px 2px 6px 0 rgba(0, 0, 0, .4);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 30px;
    letter-spacing: 1px;
    color: black;
    z-index: 1;
    transition: .6s;
    gap: 5px;
  }
  
  /* .del:hover div {
    letter-spacing: 4px;
    color: #fff;
    background: #ff0000;
  } */
  

  .container {
    background: #0D5376;
    border-radius: 15px;
    padding: 15px;
    display: flex;
    gap: 5px;
    box-shadow: 3px 5px 10px #ff7a7a, inset 3px 0 0 #ff5751d6,
      inset -3px 0 0 #ff5751d6, inset 0 3px 0 #ffffff23, inset 0 -3px 0 #00000023;
  }
  .btn {
    text-decoration: none;
    border: none;
    outline: none;
    background: #0D5376;
    padding: 10px;
    /* box-shadow: 0 0 5px #0000005a, 2px 2px 3px #00000078, */
      /* inset 1px 1px 2px #ffffff9d; */
    border-radius: 10px;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s;
    font-family: sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .btn:focus {
    animation: 0.3s clicked linear;
  }
  .cmd {
    display: flex;
    gap: 10px;
    /* flex-direction: column; */
    justify-content: center;
    align-items: flex-end;
  }
  .c {
    width: 75px;
    /* font-size: 1.5rem; */
  }
  @keyframes clicked {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(0.95);
      box-shadow: 0 0 5px #00000067, inset 0 0 3px #ffffff78;
    }
    100% {
      transform: scale(1);
    }
  }
  